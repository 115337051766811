import '../sass/footer.scss';

import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer>
      <Container fluid="md">
        <Row>
          <Col id="footer" className="m-2 mb-5">
            <Row className="py-5 footer-top">
              <Col>
                <StaticImage
                  alt="Polina Fiksson, mind, body, nutrition"
                  src="../images/logo_transperant.png"
                  width={150}
                />
              </Col>
              <Col>
                <ul>
                  <li>
                      <b>Menu</b>
                      <ul>
                          <li><Link to="/work">Work With Me</Link></li>
                          <li><Link to="/about">About Me</Link></li>
                      </ul>
                  </li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>
                      <b>Connect with me</b>
                      <ul>
                          <li><a href="mailto:polinafiksson@gmail.com"><i class="bi bi-envelope"></i> polinafiksson@gmail.com</a></li>
                          <li>
                            <ul className="footer-social-media">
                              <li>
                                <a href="https://www.facebook.com/polina.fiksson" rel="noreferrer" target="_blank" aria-label="facebook"><i class="bi bi-facebook"></i></a>
                              </li>
                              <li>
                                <a href="https://www.instagram.com/polina_fiksson" rel="noreferrer" target="_blank" aria-label="instagram"><i class="bi bi-instagram"></i></a>
                              </li>
                            </ul>
                          </li>
                      </ul>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="mx-1 pt-2 footer-bottom">
              <Col>
                <ul>
                  <li className="pe-2"><Link to="/privacy-policy" title="Privacy Policy">Privacy Policy</Link></li>
                  <li><Link to="/terms-and-conditions" title="Terms & Conditions">Terms & Conditions</Link></li>
                </ul>
              </Col>
              <Col className="align-text-bottom">
                <p className="float-end">
                  © 2021 Polina Fiksson
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
