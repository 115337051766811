import '../sass/navbar.scss';

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { StaticImage } from 'gatsby-plugin-image'

const Navigation = () => {
  return (
    <Navbar className="pf-navigation" variant="light" expand="lg">
      <Container>
      <Nav>
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/work">Work with Me</Nav.Link>
      </Nav>
      <Nav className="justify-content-center">
        <Nav.Link href="/">
          <StaticImage
            alt="Polina Fiksson, mind, body, nutrition"
            src="../images/logo_transperant.png"
            width={100}
          />
        </Nav.Link>
      </Nav>
      <Nav className="justify-content-end">
        <Nav.Link href="/about">About Me</Nav.Link>
        <Nav.Link href="/contact">Contact Me</Nav.Link>
      </Nav>
      </Container>
    </Navbar>
  );
};

export default Navigation;
