import * as React from 'react';
import Navigation from './nav';
import Footer from './footer';
import Seo from './seo';
import Container from 'react-bootstrap/Container';

const PageTemplate = (props) => {
  return (
    <div className="top-container">
      <Seo />
      <Navigation />
      <main>
        <Container fluid="md" className={props.className}>
          {props.children}
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default PageTemplate;
